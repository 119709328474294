// App.js
import React from 'react';
import NavBar from './NavBar';


function App() {
  return (
    <NavBar/>
  );
}

export default App;
